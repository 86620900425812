// pusher-plugin.js
import Pusher from "pusher-js";

const pusherPlugin = {
	install(app, options) {
		// Create a new Pusher instance
		let choptions = {
			endpoint: options.authEndpoint,
			headers: {
				Accept: "application/json",
				Authorization: null,
			},
		};
		if (typeof options.token !== "undefined") {
			choptions.headers.Authorization = options.token;
		}
		const pusher = new Pusher(options.key, {
			cluster: options.cluster,
			wsHost: options.host,
			wsPort: options.port,
			enabledTransports: ["ws", "wss"],
			encrypted: options.encrypted ?? false,
			channelAuthorization: choptions,
		});
		// Add a new $pusher property to all Vue instances
		// Vue.prototype.$pusher = pusher;
		app.config.globalProperties.$pusher = pusher;
	},
};

export default pusherPlugin;
