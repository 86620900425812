export function calculateDeviation(position, route_stops, deviation) {
	let pos = new window.google.maps.LatLng(position.lat, position.lng);
	for (let i = 0; i < route_stops.length; i++) {
		let latlng = new window.google.maps.LatLng(
			route_stops[i].lat,
			route_stops[i].lng
		);
		let dist = window.google.maps.geometry.spherical.computeDistanceBetween(
			pos,
			latlng
		);
		if (dist <= deviation) {
			return true;
		}
	}
	return false;
}

export function formatNumber(value) {
	return value
		? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
		: value;
}

function lerp(start, end, t) {
	return start * (1 - t) + end * t;
}

export function animateMarker(before, newData, duration = 100) {
	const startTime = performance.now();
	const oldLat = before.lat;
	const oldLng = before.lng;
	function updatePosition(currentTime) {
		const elapsedTime = currentTime - startTime;
		let t = elapsedTime / duration;
		if (t > 1) t = 1;

		const latitude = lerp(oldLat, newData.lat, t);
		const longitude = lerp(oldLng, newData.lng, t);
		before.lat = latitude;
		before.lng = longitude;
		before.course = newData.course;
		before.speed = newData.speed;
		before.online = newData.online;
		before.altitude = newData.altitude;
		if (t < 1) {
			requestAnimationFrame(updatePosition);
		}
	}
	requestAnimationFrame(updatePosition);
}

export function randomValueFromArray(array) {
	return array[Math.floor(Math.random() * array.length)];
}
